
"use strict"

import { init as menu } from './modules/menu.js';
import { init as validate } from './modules/validate.js';

menu(
	document.querySelector('.js-menu'), 
	document.querySelector('.js-menu-toggle')
)

validate(document.querySelector('.js-form'))